import React from "react";
// import HospAmb from "../../assets/imgs/HospAmb.png";
import "./bodyThree.scss";

export function BodyThree() {
  return (
    <section className="wrapper_section_three" id="servicios">
      {/* <img src={HospAmb}className='imagenHA'></img> */}
      <div className="box_text_three text-3xl secondaryColor">
        <p>OFRECEMOS SERVICIOS DE ALTA CALIDAD</p>
        <p>PARA EL CUIDADO DE NUESTROS PACIENTES.</p>
      </div>
    </section>
  );
}
