import React from "react";
import "./bodyTwo.scss";
import mapa from "../../assets/imgs/map.png";

export function BodyTwo() {
  return (
    <section className="wrapper_section_two">
      <div className="secondSecDesktop">
        <div className="box_text secondaryColor">
          <span className="thirdColor">Hospitales Cruz Azul </span>
          cuenta con tres sedes <br />
          y una red médica a tu servicio
          <br />
          en toda la república.
        </div>
        <div className="mapa">
          <img src={mapa} alt="mapa" />
        </div>
      </div>
      <div className="secondSecMobile">
        <div className="mapa">
          <img src={mapa} alt="mapa" />
        </div>
        <div className="box_text secondaryColor">
          <span className="hca">Hospitales Cruz Azul </span>
          cuenta con tres sedes
          y una red médica a tu servicio
          <br />
          en toda la república.
        </div>
      </div>
    </section>
  );
}
