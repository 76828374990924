import React from "react";
import "../APPacientes/appacientes.scss";

export function APTrabajadores() {
  return (
    <section className="wrapper_section_app">
      <div className="topText">
        <div className="bolderText secondaryColor">
          AVISO DE PRIVACIDAD <br />
        </div>
        <div className="boldText secondaryColor">
          INTEGRAL PARA TRABAJADORES
        </div>
        <br />
        <div className="parraph">
          <span className="txtDarkBlue">Hospitales Cruz Azul SA de CV</span> (en
          lo sucesivo identificada como “
          <span className="txtNavyBlue">Hospitales Cruz Azul</span>”), con
          domicilio en Anillo Periférico Sur, número 5550-5, colonia Pedregal de
          Carrasco, alcaldía Coyoacán, CP 04700, Ciudad de México, y de
          conformidad con la{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares
          </span>{" "}
          (en lo sucesivo la Ley) y demás disposiciones aplicables informa el
          presente Aviso de Privacidad en relación con los servicios ofrecidos.
          <br />
          <br />
          Ante cualquier duda, comentario, notificación, queja o recomendación
          al respecto del presente Aviso de Privacidad y sobre el manejo de los
          datos personales, las personas podrán acudir al domicilio referido en
          el párrafo que antecede.
          <br />
          <br />
          <h5>I.- Fundamento legal.</h5>
          <br />
          Hospitales Cruz Azul pone a disposición el presente Aviso de
          Privacidad, de conformidad con lo establecido en los artículos{" "}
          <span className="txtNavyBlue">8, 15, 16, 33 y 36</span> de la{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares
          </span>
          ; <span className="txtNavyBlue">14, 41, 68, 90 y 102</span> del{" "}
          <span className="txtBlue">
            Reglamento de la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares
          </span>{" "}
          y del numeral vigésimo de los Lineamientos del Aviso de Privacidad y
          demás relativos en la materia.
          <br />
          <br />
          <h5>II.- Definiciones.</h5>
          <br />
          Hospitales Cruz Azul manifiesta que, para los efectos del presente
          Aviso de Privacidad, se manejarán las siguientes definiciones
          relativos en la materia:
          <br />
          <br />
          <ol type="A">
            <li>
              <span className="title">
                Responsable de los datos personales:
              </span>
              <span className="txtDarkBlue">
                {" "}
                para efectos del presente Aviso de Privacidad, Hospitales Cruz
                Azul será la persona moral encargada del tratamiento de los
                datos personales proporcionados por el paciente.
              </span>
            </li>
            <li>
              <span className="title">Titular de los datos personales:</span>
              <span className="txtDarkBlue">
                {" "}
                para efectos del presente Aviso de Privacidad, el paciente, será
                aquella persona física que haya solicitado y recibido los
                servicios médicos que ofrece Hospitales Cruz Azul y por lo cual
                tuvo que proporcionarle sus datos personales al responsable.
              </span>
            </li>
            <li>
              <span className="title">Tratamiento:</span>
              <span className="txtDarkBlue">
                {" "}
                la obtención, uso, divulgación o almacenamiento de datos
                personales, por cualquier medio. El uso abarca cualquier acción
                de acceso, manejo, aprovechamiento, transferencia o disposición
                de datos personales.
              </span>
            </li>
          </ol>
          <br />
          <h5>III.- Principios rectores del Aviso de Privacidad:</h5>
          <br />
          Hospitales Cruz Azul reconoce y respeta los datos personales que le
          son entregados por parte del titular, quien ejerce por medio del
          presente acto su derecho de autodeterminación informativa, entendiendo
          por este su conocimiento de manera efectiva, sobre la existencia del
          tratamiento de sus datos personales, por lo que, en cualquier momento,
          el uso de los datos privados del titular serán tratados por Hospitales
          Cruz Azul, bajo los principios rectores de lealtad, licitud, calidad,
          proporcionalidad y responsabilidad, garantizando el adecuado manejo de
          los mismos y con ello se permite su adecuada tutela, conforme a lo
          dispuesto en la{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares
          </span>{" "}
          y demás normatividad aplicable en la materia.
          <br />
          <br />
          <h5>IV.- Consentimiento.</h5>
          <br />
          Al proveer la información, el titular automáticamente permite a
          Hospitales Cruz Azul usar sus datos personales para las finalidades
          mencionadas en el presente Aviso de Privacidad.
          <br />
          <br />
          Hospitales Cruz Azul en ningún momento será responsable de la
          veracidad de los datos que haya proporcionado el titular.
          <br />
          <br />
          <h5>
            V.- Datos personales que serán obtenidos y tratados por el
            “responsable”.
          </h5>
          <br />
          Hospitales Cruz Azul recabará de los proveedores, ya sea de forma
          directa, personal y/o cualquier medio electrónico, diversos datos
          personales mismos que de manera enunciativa, mas no limitativa se
          enlistan a continuación:
          <br />
          <br />
          <ol>
            <li>
              <span className="title">
                Datos de identificación del trabajador:{" "}
              </span>
              <span className="txtDarkBlue">
                nombre completo, edad, sexo, fecha de nacimiento, lugar de
                nacimiento, nacionalidad, número de Seguridad Social, Registro
                Federal de Contribuyentes, Clave Única de Registro de Población
                y firma autógrafa.
              </span>
            </li>
            <li>
              <span className="title">Datos de contacto: </span>
              <span className="txtDarkBlue">
                domicilio completo, teléfono fijo y/o móvil y correo
                electrónico.
              </span>
            </li>
            <li>
              <span className="title">Datos financieros o patrimoniales: </span>
              <span className="txtDarkBlue">
                información resultante de los estudios socioeconómicos que se
                lleven a cabo, datos de la cuenta bancaria del trabajador,
                información relacionada con su Afore e Infonavit.
              </span>
            </li>
            <li>
              <span className="title">Datos laborales y académicos: </span>
              <span className="txtDarkBlue">
                antecedentes laborales y académicos, certificados de estudio,
                curriculum vitae, registros de desempeño laboral, información
                relacionada con trabajos anteriores, historial de ascensos
                laborales y salario.
              </span>
            </li>
            <li>
              <span className="title">
                Datos de identificación u contacto de terceros:{" "}
              </span>
              <span className="txtDarkBlue">
                toda la información que permita obtener de terceras referencias,
                tanto personales, como laborales del trabajador y en caso de
                aplicar datos de la persona designada para ser contactada en
                caso de emergencia.
              </span>
            </li>
          </ol>
          <br />
          <h5>
            VI.- Datos personales sensibles que serán sometidos a tratamiento.
          </h5>
          <br />
          Hospitales Cruz Azul también podrá tratar datos personales que se
          consideran como sensibles, la información sensible recabada por
          Hospitales Cruz Azul, debido a su naturaleza jurídica requieren de
          especial protección, los cuales, en su caso, serán utilizados única y
          exclusivamente para temas internos, datos que de manera enunciativa,
          mas no limitativa se enlistan a continuación:
          <br />
          <br />
          <ol>
            <li>
              <span className="txtDarkBlue">Examen psicométrico.</span>
            </li>
            <li>
              <span className="txtDarkBlue">Examen de conocimientos.</span>
            </li>
            <li>
              <span className="txtDarkBlue">Examen médico.</span>
            </li>
            <li>
              <span className="txtDarkBlue">Evaluaciones de desempeño.</span>
            </li>
            <li>
              <span className="txtDarkBlue">Religión.</span>
            </li>
            <li>
              <span className="txtDarkBlue">Estado civil.</span>
            </li>
          </ol>
          <br />
          Lo anterior se requiere para administrar la relación contractual y
          legal entre Hospitales Cruz Azul y el proveedor de los bienes o
          servicios.
          <br />
          <br />
          <h5>VII.- Finalidad de los datos personales obtenidos.</h5>
          <br />
          Los datos personales que Hospitales Cruz Azul obtiene del titular, así
          como su uso, acceso, manejo, aprovechamiento, transferencia,
          disposición, almacenamiento o divulgación en lo sucesivo “Tratamiento”
          serán usados para:
          <br />
          <br />
          <ol>
            <li>
              <span className="txtDarkBlue">
                Celebrar una relación laboral entre Hospitales Cruz Azul y el
                titular de los datos personales.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Solicitar referencias personales y laborales del titular de los
                datos personales.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Formar el expediente laboral del trabajador.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Dar de alta al trabajador y sus beneficiarios en el Instituto
                Mexicano del Seguro Social.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Otorgarle el acceso a las instalaciones y a los sistemas de
                cómputo y/o industriales con los que cuenta Hospitales Cruz
                Azul.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Proporcionar capacitación y/o entrenamiento para ejercer de
                manera correcta las funciones que le fueron asignadas como
                trabajador.
              </span>
            </li>
          </ol>
          <br />
          Lo anterior se requiere para administrar la relación contractual y
          legal que rige a Hospitales Cruz Azul y al titular, incluyendo el
          cumplimiento de obligaciones o la implementación de cualquier programa
          o política que sea aplicable al titular.
          <br />
          <br />
          <h5>VIII.- Transferencias de datos personales.</h5>
          <br />
          Hospitales Cruz Azul podrá transferir sus datos personales a terceros
          dentro y/o fuera del país, para los fines establecidos en este aviso,
          siendo los terceros a los que se transferirán sus datos personales los
          siguientes:
          <br />
          <br />
          <ol>
            <li>
              <span className="txtDarkBlue">
                Instituciones bancarias: para solicitar la apertura de una
                cuenta y realizar el pago de salarios y demás percepciones.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Instituto Mexicano del Seguro Social: dar de alta a los
                trabajadores con el objeto de que puedan acceder a los servicios
                de salud pública.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Instituto del Fondo Nacional de la Vivienda para los
                Trabajadores: dar de alta a los trabajadores con el objeto de
                que una vez que se reúnan todos los requisitos, el trabajador
                puede ser acreedor a un crédito hipotecario.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Empresas filiales y subsidiarias de Hospitales Cruz Azul: para
                controles internos y estadísticas.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Autoridades competentes: para cumplimentar requerimientos
                legales o solicitudes de autoridades, para salvaguardar el
                interés público o para la procuración o administración de
                justicia, o bien, para el reconocimiento ejercicio o defensa de
                un derecho en un proceso judicial, así como en los casos
                específicamente permitidos por la ley.
              </span>
            </li>
          </ol>
          <br />
          <br />
          <h5>IX.- Procedimiento para ejercer los Derechos ARCO.</h5>
          <br />
          Usted tiene derecho a conocer que datos personales tenemos, para que
          los utilizamos y las condiciones del uso que les damos{" "}
          <span className="txtDarkBlue">(acceso).</span>
          <br />
          <br />
          Asimismo, es su derecho solicitar la corrección de su información
          personal en caso de que este desactualizado, sea inexacta o incompleta{" "}
          <span className="txtDarkBlue">(rectificación)</span>, que la
          eliminemos de nuestros registros o bases de datos cuando se considere
          que la misma no está siendo utilizada conforme a los principios
          deberes y obligaciones previstas en la normativa{" "}
          <span className="txtDarkBlue">(cancelación)</span>, así como oponerse
          al uso de sus datos personales para fines específicos
          <span className="txtDarkBlue">(oposición)</span>, estos derechos se
          conocen como derechos ARCO.
          <br />
          <br /> El ejercicio de los derechos anteriormente señalados podrán ser
          hechos valer por el titular y/o un representante legal acudiendo
          personalmente al siguiente domicilio: Anillo Periférico Sur, número
          5550-5, alcaldía Coyoacán, colonia Pedregal de Carrasco, CP 04700,
          Ciudad de México, acompañando la siguiente documentación e
          información:
          <br />
          <br />
          <ol>
            <li>
              <span className="title">
                Datos de identificación del titular de los datos personales,{" "}
              </span>
              <span className="txtDarkBlue">
                como lo es nombre, apellidos, domicilio, correo electrónico y
                número de teléfono.
              </span>
            </li>
            <li>
              <span className="title">
                En caso de solicitarse a través de su representante legal,{" "}
              </span>
              <span className="txtDarkBlue">
                se deberá de acompañar copia del documento que acredite dicha
                personalidad.
              </span>
            </li>
            <li>
              <span className="title">Domicilio </span>
              <span className="txtDarkBlue">
                para oír y recibir la respuesta emitida por parte de Hospitales
                Cruz Azul, así como cualquier comunicación que pudiere surgir de
                la solicitud por parte del beneficiario y/o derechohabiente,
                además en su caso, cualquier notificación que se llegare a
                realizar o, en su caso, un correo electrónico que se establezca
                para este mismo fin.
              </span>
            </li>
            <li>
              <span className="title">Una descripción </span>
              <span className="txtDarkBlue">
                detallada congruente, clara y precisa, de cuál es el derecho que
                desea ejercer el beneficiario y/o derechohabiente, así como la
                descripción respecto de que Información Personal el beneficiario
                y/o derechohabiente desee hacer valer sus derechos ARCO.
              </span>
            </li>
            <li>
              <span className="title">Copia de la identificación oficial </span>
              <span className="txtDarkBlue">
                vigente del beneficiario y/o derechohabiente o su representante.
              </span>
            </li>
            <li>
              <span className="title">Rúbrica </span>
              <span className="txtDarkBlue">
                en cada una de las hojas, así como la firma al final de la
                solicitud del titular de la Información Personal.
              </span>
            </li>
          </ol>
          <br />
          <br />
          <h5>X.- Revocación del consentimiento.</h5>
          <br />
          En cualquier momento, el titular de los datos personales podrá revocar
          el consentimiento que, en su caso, haya otorgado a Hospitales Cruz
          Azul para el tratamiento de sus datos personales, siempre y cuando,
          dicha revocación sea en conformidad con la ley y no implique alguna
          imposibilidad de cumplir obligaciones derivadas de una relación
          jurídica vigente entre el titular y Hospitales Cruz Azul.
          <br />
          <br />
          Sin embargo, es importante que tenga en cuenta que no en todos los
          casos podremos atender su solicitud o concluir el uso de forma
          inmediata, ya que es posible que por alguna obligación legal
          requiramos seguir tratando sus datos personales, lo anterior de
          conformidad con lo establecido en el{" "}
          <span className="txtDarkBlue">
            artículo 10 fracción IV de la
          </span>{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares.
          </span>
          <br />
          <br />
          Asimismo, usted deberá considerar que, para ciertos fines, la
          revocación de su consentimiento implicará entre otras cosas la
          conclusión de su relación y asuntos generales con Hospitales Cruz
          Azul.
          <br />
          <br /> Para revocar su consentimiento deberá presentar su solicitud en
          Anillo Periférico Sur, número 5550-5, alcaldía Coyoacán, colonia
          Pedregal de Carrasco, CP 04700, Ciudad de México.
          <br />
          <br />
          <h5>XI.- Seguridad de información personal.</h5>
          <br />
          Hospitales Cruz Azul se compromete a salvaguardar la confidencialidad
          de sus datos personales, usando y manteniendo las medidas de seguridad
          necesarias a fin de proteger las bases de datos o los documentos
          fìsicos, para evitar su pérdida, alteración, destrucción, uso, acceso
          o tratamiento no autorizado.
          <br />
          <br />
          <h5>XII.- Modificaciones al Aviso de Privacidad.</h5> <br /> Hospitales
          Cruz Azul se reserva el derecho de efectuar en cualquier momento
          modificaciones o actualizaciones al presente Aviso de Privacidad
          debido a modificaciones operativas, legislativas o cuando se considere
          necesario.
          <br />
          <br /> Hospitales Cruz Azul tendrá en todo momento el presente Aviso
          de Privacidad actualizado en la página web:{" "}
          <a href="https://hospitalescruzazul.com.mx/">
            https://hospitalescruzazul.com.mx/
          </a>{" "}
          o cualquier otro medio de comunicación verbal, impreso o electrónico
          que Hospitales Cruz Azul determine para tal efecto.
          <br />
          <br />
          Ante cualquier duda, comentario, notificación, queja o recomendación
          al respecto del presente aviso de privacidad ysobre el manejo de los
          datos personales, las personas podrán acudir al domicilio ubicado en
          Anillo Periférico Sur, número 5550-5, alcaldía Coyoacán, colonia
          Pedregal de Carrasco, CP 04700, Ciudad de México.
          <br />
          <br />
          <br />
          Ultima actualización del presente Aviso de Privacidad:
          <br />
          <span className="txtDarkBlue">05 de octubre de 2023.</span>
          <br />
          <br /> <br />
          <br />
        </div>
      </div>
    </section>
  );
}
