import "./App.scss";
// import PopUp from "./components/popUp/popUp";
import { Footer } from "./components/footer/footer";
// import Content from "./components/content/content";
// import BurguerMenu from "./components/navbar/burguerMenu";
import BurguerMenuMobile from "./components/navbar/burguerMenuMobile";
import BurguerMenuDesktop from "./components/navbar/burguerMenuDesktop";
import Rutas from "./components/Routes";
import { BodySeven } from "./components/bodySeven/bodySeven";
import { BodyEight } from "./components/bodyEight/bodyEight";

function App() {
  return (
    <section>
      {/* <PopUp /> */}
        <BurguerMenuDesktop className="BMD"/>
        <BurguerMenuMobile className="BMM"/>
      {/* <Content /> */}
      <section className="wrapper_section_content">
      <Rutas/>
      </section>
      <BodySeven/>
      <BodyEight/>
      <Footer />
    </section>
  );
}

export default App;
