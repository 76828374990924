import React from "react";
import "./appacientes.scss";

export function APPacientes() {
  return (
    <section className="wrapper_section_app">
      <div className="topText">
        <div className="bolderText secondaryColor">
          AVISO DE PRIVACIDAD <br />
        </div>
        <div className="boldText secondaryColor">INTEGRAL PARA PACIENTES</div>
        <br />
        <div className="parraph">
          <span className="txtDarkBlue">Hospitales Cruz Azul SA de CV</span> (en
          lo sucesivo identificada como “
          <span className="txtNavyBlue">Hospitales Cruz Azul</span>”), con
          domicilio en Anillo Periférico Sur, número 5550-5, colonia Pedregal de
          Carrasco, alcaldía Coyoacán, CP 04700, Ciudad de México, y de
          conformidad con la{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares
          </span>{" "}
          (en lo sucesivo la Ley) y demás disposiciones aplicables informa el
          presente Aviso de Privacidad en relación con los servicios ofrecidos.
          <br />
          <br />
          Ante cualquier duda, comentario, notificación, queja o recomendación
          al respecto del presente Aviso de Privacidad y sobre el manejo de los
          datos personales, las personas podrán acudir al domicilio referido en
          el párrafo que antecede.
          <br />
          <br />
          <h5>I.- Fundamento legal.</h5>
          <br />
          Hospitales Cruz Azul pone a disposición el presente Aviso de
          Privacidad, de conformidad con lo establecido en los artículos{" "}
          <span className="txtNavyBlue">8, 15, 16, 33 y 36</span> de la{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares
          </span>
          ; <span className="txtNavyBlue">14, 41, 68, 90 y 102</span> del{" "}
          <span className="txtBlue">
            Reglamento de la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares
          </span>{" "}
          y del numeral vigésimo de los Lineamientos del Aviso de Privacidad y
          demás relativos en la materia.
          <br />
          <br />
          <h5>II.- Definiciones.</h5>
          <br />
          Hospitales Cruz Azul manifiesta que, para los efectos del presente
          Aviso de Privacidad, se manejarán las siguientes definiciones
          relativos en la materia:
          <br />
          <br />
          <ol type="A">
            <li>
              <span className="title">
                Responsable de los datos personales:
              </span>
              <span className="txtDarkBlue">
                {" "}
                para efectos del presente Aviso de Privacidad, Hospitales Cruz
                Azul será la persona moral encargada del tratamiento de los
                datos personales proporcionados por el paciente.
              </span>
            </li>
            <li>
              <span className="title">Titular de los datos personales:</span>
              <span className="txtDarkBlue">
                {" "}
                para efectos del presente Aviso de Privacidad, el paciente, será
                aquella persona física que haya solicitado y recibido los
                servicios médicos que ofrece Hospitales Cruz Azul y por lo cual
                tuvo que proporcionarle sus datos personales al responsable.
              </span>
            </li>
            <li>
              <span className="title">Tratamiento:</span>
              <span className="txtDarkBlue">
                {" "}
                la obtención, uso, divulgación o almacenamiento de datos
                personales, por cualquier medio. El uso abarca cualquier acción
                de acceso, manejo, aprovechamiento, transferencia o disposición
                de datos personales.
              </span>
            </li>
          </ol>
          <br />
          <h5>III.- Principios rectores del Aviso de Privacidad:</h5>
          <br />
          Hospitales Cruz Azul reconoce y respeta los datos personales que le
          son entregados por parte del titular, quien ejerce por medio del
          presente acto su derecho de autodeterminación informativa, entendiendo
          por este su conocimiento de manera efectiva, sobre la existencia del
          tratamiento de sus datos personales, en cualquier momento, el uso de
          los datos privados del titular serán tratados por Hospitales Cruz
          Azul, bajo los principios rectores de lealtad, licitud, calidad,
          proporcionalidad y responsabilidad, garantizando el adecuado manejo de
          los mismos y con ello se permite su adecuada tutela, conforme a lo
          dispuesto en la{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares
          </span>{" "}
          y demás normatividad aplicable en la materia.
          <br />
          <br />
          <h5>IV.- Consentimiento.</h5>
          <br />
          Al proveer la información, el titular automáticamente permite a
          Hospitales Cruz Azul usar sus datos personales para las finalidades
          mencionadas en el presente Aviso de Privacidad.
          <br /><br/>
          Hospitales Cruz Azul en ningún momento será responsable de la
          veracidad de los datos que haya proporcionado el titular.
          <br />
          <br />
          <h5>
            V.- Datos personales que serán obtenidos y tratados por el
            “responsable”.
          </h5>
          <br />
          Hospitales Cruz Azul recabará del paciente, ya sea de forma directa,
          personal y/o cualquier medio electrónico, diversos datos personales
          mismos que de manera enunciativa, mas no limitativa se enlistan a
          continuación:
          <br />
          <br />
          <ol>
            <li>
              <span className="title">
                Datos de identificación de paciente:
              </span>
              <span className="txtDarkBlue">
                nombre completo, domicilio, teléfono, correo electrónico, estado
                civil, edad, nacionalidad y fecha de nacimiento o cualquier otro
                conveniente.
              </span>
            </li>
            <li>
              <span className="title">Datos de contacto o filiación:</span>
              <span className="txtDarkBlue">
                nombre, domicilio y teléfono del contacto asignado como
                responsable del paciente o cualquier otro conveniente.
              </span>
            </li>
            <li>
              <span className="title">
                Datos del seguro en caso de aplicar:
              </span>
              <span className="txtDarkBlue">
                número de póliza, nombre del titular de la póliza, tipo de
                seguro, vigencia, tipo de cobertura, prima asegurada, deducible
                y coaseguro, tratamientos y enfermedades cubiertas.
              </span>
            </li>
            <li>
              <span className="title">Datos financieros:</span>
              <span className="txtDarkBlue">
                datos bancarios del paciente, como lo es nombre del banco y del
                titular de la cuenta, número de cuenta y clabe interbancaria.
              </span>
            </li>
          </ol>
          <br />
          <br />
          <h5>
            VI.- Datos personales sensibles que serán sometidos a tratamiento.
          </h5>
          <br />
          Hospitales Cruz Azul también podrá tratar los siguientes datos
          personales que se consideran como sensibles, la información sensible
          recabada por Hospitales Cruz Azul, debido a su naturaleza jurídica
          requieren de especial protección, los cuales, en su caso, serán
          utilizados única y exclusivamente para brindar atención
          medica-hospitalaria a los pacientes, datos que de manera enunciativa,
          mas no limitativa se enlistan a continuación:
          <br />
          <br />
          <ol>
            <li>
              <span className="title">Expediente clínico:</span>
              <span className="txtDarkBlue">
                contiene información relacionada con el estado de salud del
                paciente, así como información y datos personales de un paciente
                para su atención médica, consta de documentos escritos,
                gráficos, imagenológicos, electrónicos, magnéticos,
                electromagnéticos, ópticos, magneto-ópticos y de cualquier otra
                índole, en los que constan los registros, anotaciones, en su
                caso, constancias y certificaciones sobre la intervención en la
                atención medica del paciente, diagnóstico, tratamiento, tipo de
                sangre, enfermedades, medicamentos suministrados y dosis
                prescritas entre otros necesarios.
              </span>
            </li>
            <li>
              <span className="title">Fotografía o videos:</span>
              <span className="txtDarkBlue">
                imagen de una persona, en su caso, de su rostro, cuyo registro
                fotográfico da cuenta de las características inherentes a su
                persona, entre otros, de su media filiación, o bien, de sus
                rasgos físicos, tipo de cejas, ojos, pómulos, nariz, labios,
                mentón, cabello, entre otros, los cuales constituyen datos
                personales sensibles que se relacionan con la salud del
                paciente.
              </span>
            </li>
          </ol>
          <br />
          Los datos personales sensibles serán mantenidos y tratados con
          estricta seguridad y confidencialidad para fines relacionados con la
          prestación de servicios de salud y conforme a los términos
          establecidos en el presente Aviso de Privacidad.
          <br />
          <br />
          <h5>VII.- Finalidad de los datos personales obtenidos.</h5>
          <br />
          Los datos personales que Hospitales Cruz Azul obtiene del titular, así
          como su uso, acceso, manejo, aprovechamiento, transferencia,
          disposición, almacenamiento o divulgación en lo sucesivo “tratamiento”
          serán usadas para:
          <br />
          <br />
          <ol>
            <li>
              <span className="txtDarkBlue">
                Proporcionar al paciente los servicios médicos que ofrece
                Hospitales Cruz Azul.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Creación historial clínico del paciente.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Trámites de facturación y cobranza.
              </span>
            </li>
            <li>
              <span className="txtDarkBlue">
                Trasladar al paciente a otros hospitales de la red de Hospitales
                Cruz Azul.
              </span>
            </li>
          </ol>
          <br />
          Lo anterior se requiere para administrar la relación contractual y
          legal que rige a Hospitales Cruz Azul y al titular, incluyendo el
          cumplimiento de obligaciones o la implementación de cualquier programa
          o política que sea aplicable al titular.
          <br />
          <br />
          <h5>VIII.- Transferencias de datos personales.</h5>
          <br />
          Hospitales Cruz Azul podrá transferir sus datos personales a terceros
          dentro y/o fuera del país, para los fines establecidos en este aviso.
          <br />
          <br /> Los terceros a los que se transferirán dichos datos son:
          laboratorios, clínicas, hospitales, centros de investigación,
          aseguradoras, así como a cualquier empresa del mismo grupo
          corporativo, o en su caso autoridades competentes.
          <br />
          <br /> No será necesario el consentimiento de los titulares cuando las
          transferencias se realicen a empresas filiales de Hospitales Cruz
          Azul, cuando dichas transferencias sean necesarias para la prevención
          o el diagnóstico médico, la prestación de asistencia sanitaria,
          tratamiento médico la gestión de servicios sanitarios, lo anterior de
          conformidad con lo establecido en el{" "}
          <span className="txtDarkBlue">artículo 37 fracción II de la </span>
          <span className="txtBlue">
            {" "}
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares.
          </span>{" "}
          <br />
          <br />
          Los datos personales recabados no serán transferidos a terceros
          distintos a los entes señalados sin el consentimiento del titular. En
          caso de ser necesario la transferencia de datos personales a terceros,
          se proporcionará un formato o documento en el que se le solicite
          brindar de manera expresa su autorización para la transferencia.
          <br />
          <br />
          <h5>IX.- Procedimiento para ejercer los Derechos ARCO.</h5>
          <br />
          Usted tiene derecho a conocer que datos personales tenemos, para que
          los utilizamos y las condiciones del uso que les damos{" "}
          <span className="txtDarkBlue">(acceso).</span>
          <br />
          <br />
          Asimismo, es su derecho solicitar la corrección de su información
          personal en caso de que este desactualizado, sea inexacta o incompleta{" "}
          <span className="txtDarkBlue">(rectificación)</span>, que la
          eliminemos de nuestros registros o bases de datos cuando se considere
          que la misma no está siendo utilizada conforme a los principios
          deberes y obligaciones previstas en la normativa{" "}
          <span className="txtDarkBlue">(cancelación)</span>, así como oponerse
          al uso de sus datos personales para fines específicos
          <span className="txtDarkBlue">(oposición)</span>, estos derechos se
          conocen como derechos ARCO.
          <br />
          <br /> El ejercicio de los derechos anteriormente señalados podrán ser
          hechos valer por el titular y/o un representante legal acudiendo
          personalmente al siguiente domicilio: Anillo Periférico Sur, número
          5550-5, alcaldía Coyoacán, colonia Pedregal de Carrasco, CP 04700,
          Ciudad de México, acompañando la siguiente documentación e
          información:
          <br />
          <br />
          <ol>
            <li>
              <span className="title">
                Datos de identificación del titular de los datos personales,{" "}
              </span>
              <span className="txtDarkBlue">
                como lo es nombre, apellidos, domicilio, correo electrónico y
                número de teléfono.
              </span>
            </li>
            <li>
              <span className="title">
                En caso de solicitarse a través de su representante legal,{" "}
              </span>
              <span className="txtDarkBlue">
                se deberá de acompañar copia del documento que acredite dicha
                personalidad.
              </span>
            </li>
            <li>
              <span className="title">Domicilio </span>
              <span className="txtDarkBlue">
                para oír y recibir la respuesta emitida por parte de Hospitales
                Cruz Azul, así como cualquier comunicación que pudiere surgir de
                la solicitud por parte del beneficiario y/o derechohabiente,
                además en su caso, cualquier notificación que se llegare a
                realizar o, en su caso, un correo electrónico que se establezca
                para este mismo fin.
              </span>
            </li>
            <li>
              <span className="title">Una descripción </span>
              <span className="txtDarkBlue">
                detallada congruente, clara y precisa, de cuál es el derecho que
                desea ejercer el beneficiario y/o derechohabiente, así como la
                descripción respecto de que Información Personal el beneficiario
                y/o derechohabiente desee hacer valer sus derechos ARCO.
              </span>
            </li>
            <li>
              <span className="title">Copia de la identificación oficial </span>
              <span className="txtDarkBlue">
                vigente del beneficiario y/o derechohabiente o su representante.
              </span>
            </li>
            <li>
              <span className="title">Rúbrica </span>
              <span className="txtDarkBlue">
                en cada una de las hojas, así como la firma al final de la
                solicitud del titular de la Información Personal.
              </span>
            </li>
          </ol>
          <br />
          <br />
          <h5>X.- Revocación del consentimiento.</h5>
          <br />
          En cualquier momento, la persona podrá revocar el consentimiento que,
          en su caso, nos haya otorgado para el tratamiento de sus datos
          personales, siempre y cuando, dicha revocación sea en conformidad con
          la ley y no implique alguna imposibilidad de cumplir obligaciones
          derivadas de una relación jurídica vigente entre el beneficiario y
          Hospitales Cruz Azul. <br />
          <br />
          Sin embargo, es importante que tenga en cuenta que no en todos los
          casos podremos atender su solicitud o concluir el uso de forma
          inmediata, ya que es posible que por alguna obligación legal
          requiramos seguir tratando sus datos personales, lo anterior de
          conformidad con lo establecido en el{" "}
          <span className="txtDarkBlue">
            artículo 10 fracción VI de la
          </span>{" "}
          <span className="txtBlue">
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares.
          </span>
          <br />
          <br />
          Asimismo, usted deberá considerar que, para ciertos fines, la
          revocación de su consentimiento implicará entre otras cosas la
          conclusión de su relación y asuntos generales con Hospitales Cruz
          Azul.
          <br />
          <br /> Para revocar su consentimiento deberá presentar su solicitud en
          Anillo Periférico Sur, número 5550-5, alcaldía Coyoacán, colonia
          Pedregal de Carrasco, CP 04700, Ciudad de México.
          <br />
          <br />
          <h5>XI.- Seguridad de información personal.</h5>
          <br />
          Hospitales Cruz Azul se compromete a salvaguardar la confidencialidad
          de sus datos personales, usando y manteniendo las medidas de seguridad
          necesarias a fin de proteger las bases de datos, para evitar su
          pérdida, alteración, destrucción, uso, acceso o tratamiento no
          autorizado.
          <br />
          <br />
          <h5>XII.- Modificaciones al Aviso de Privacidad.</h5> <br />{" "}
          Hospitales Cruz Azul se reserva el derecho de efectuar en cualquier
          momento modificaciones o actualizaciones al presente Aviso de
          Privacidad debido a modificaciones operativas, legislativas o cuando
          se considere necesario. Hospitales Cruz Azul tendrá en todo momento el
          presente Aviso de Privacidad actualizado en la página web:{" "}
          <a href="https://hospitalescruzazul.com.mx/">
            https://hospitalescruzazul.com.mx/
          </a>{" "}
          o cualquier otro medio de comunicación verbal, impreso o electrónico
          que Hospitales Cruz Azul determine para tal efecto.
          <br />
          <br />
          Ultima actualización del presente Aviso de Privacidad:
          <br />
          <span className="txtDarkBlue">05 de octubre de 2023.</span>
          <br />
          <br /> <br />
          <br />
        </div>
      </div>
    </section>
  );
}
