import React from "react";
import Mountain from "../../assets/imgs/Mountain.png";
import Lighthouse from "../../assets/imgs/Lighthouse.png";
import Valores from "../../assets/imgs/valores.png";
import "./mvv.scss";
export function Mvv() {
  return (
    <section className="mvv">
      <div className="mvwb">
        <div className="mw">
          <div className="ins">
            <img src={Mountain} alt="misión"/>
            <span className="txtDarkBlue">
              Somos un grupo de{" "}
              <span className="txtDarkBlue-blk">hospitales</span> que <br />
              brinda servicios de salud. Estamos 
              <br /> comprometidos con la prevención 
              <br /> y la atención centrada en la persona.
            </span>
          </div>
        </div>
        <div className="vb">
          <div className="ins">
            <span className="txtWhite">
              Ser <span className="txtWhite-blk">reconocidos</span> como el
              grupo<br/> de hospitales con la mejor<br/> atención médica en la región.
            </span>
            <img src={Lighthouse} alt="visión"/>
          </div>
        </div>
      </div>
      <div className="val">
        <img src={Valores} alt="valores"/>
      </div>
      <div className="pur">
        <span className="badge txtBlue">NUESTRO PROPÓSITO:</span>
        <br />
        <span className="txtWhite-blk">
          Acercar la mejor atención médica
          <br />a todas las regiones en{" "}
          <span className="txtWhite-lb">México.</span>
        </span>
      </div>
    </section>
  );
}
