import React, { useState, useRef, useEffect } from "react";
import "./burguerMenuDesktop.scss";
import logo from "../../assets/imgs/Logo.png";

const BurguerMenuDesktop = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const menuRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [open]);
  const handleOutsideClicks = (event) => {
    if (open && menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
      console.log("open: " + menuRef.current);
    }
  };

  const actualUrl = window.location.pathname;
  let className = "btn";
  const urls = ["/appacientes", "/approveedores", "/aptrabajadores"];
  if (urls.includes(actualUrl)) {
    className = "btn active";
  }
  
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(!open1);
  };
  const menuRef1 = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks1);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks1);
    };
  }, [open1]);

  const handleOutsideClicks1 = (event) => {
    if (open1 && menuRef1.current && !menuRef1.current.contains(event.target)) {
      setOpen1(false);
      console.log("open: " + menuRef1.current);
    }
  };

  return (
    <nav className="sticky-section">
      <div className="box_brand">
        <a href="/">
          <img src={logo} className="logo" alt="logo" />
        </a>
      </div>
      <div className="box_options">
        <ul>
          <li>
            <a href="/#top">Quiénes somos</a>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <button className="btn" onClick={handleOpen}>
              Servicios y contacto
                {open ? (
                  <ul className="menu" ref={menuRef}>
                    <li className="menu-item_special">
                      <a className="buttonMenu">Servicios y contacto</a>
                    </li>
                    <li className="menu-item">
                      <a href="/#oaxaca" className="buttonMenu">
                        Oaxaca
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="/#cdmx" className="buttonMenu">
                        Ciudad de México
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="/#hidalgo" className="buttonMenu">
                        Hidalgo
                      </a>
                    </li>
                  </ul>
                ) : null}
              </button>
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <button className={className} onClick={handleOpen1}>
                Aviso de Privacidad
                {open1 ? (
                  <ul className="menu" ref={menuRef1}>
                    <li className="menu-item_special">
                      <span className="buttonMenu">Aviso de Privacidad</span>
                    </li>
                    <li className="menu-item">
                      <a href="/appacientes" className="buttonMenu">
                        Pacientes
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="/approveedores" className="buttonMenu">
                        Proveedores
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="/aptrabajadores" className="buttonMenu">
                        Trabajadores
                      </a>
                    </li>
                  </ul>
                ) : null}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default BurguerMenuDesktop;
