import React from "react";
import "./bodySeven.scss";

export function BodySeven() {
  return (
    <section className="wrapper_section_seven">
      <p className="minitext text-sm">SI NECESITAS ORIENTACIÓN MÉDICA, LLAMA A NUESTRA LÍNEA DE</p>
      <p className="fifthColor text-2xl">ATENCIÓN 24 HORAS</p>
      <a href="tel:8006334222" className="tel secondaryColor text-6xl">800 633 <span className="tel fifthColor text-6xl">4222</span></a>
    </section>
  );
}
