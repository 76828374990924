import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Content from "./content/content";
import { APPacientes } from "./APPacientes/appacientes";
import { APProveedores } from "./APProveedores/approveedores";
import { APTrabajadores } from "./APTrabajadores/aptrabajadores";


const Rutas = () => {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={<Content />} />
                <Route
                    path="/home"
                    element={<Content />} />
                <Route
                    path="/appacientes"
                    element={<APPacientes />} />
                <Route
                    path="/approveedores"
                    element={<APProveedores/>} />
                <Route
                    path="/aptrabajadores"
                    element={<APTrabajadores/>}
                    />
            </Routes>
        </Router>
    )
}

export default Rutas;