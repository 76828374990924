import React from "react";
import "./bodyFive.scss";
import franja from "../../assets/imgs/franja.png";

export function BodyFive() {
  return (
    <section className="wrapper_section_five" id="cdmx">
      <div className="topFive">
        <div className="topTitle">
          <p>HOSPITALES CRUZ AZUL</p>
          <p className="secondText">CIUDAD DE MÉXICO</p>
        </div>
      </div>
      <br />
      <div className="bottomFive">
        <div className="menus">
          <div className="textContainer">
            <p className="titleBlue">MEDICINA GENERAL</p>
            <p className="titleBlue">ATENCIONES MÉDICAS MENORES</p>
            <p className="titleBlue">PEDIATRÍA</p>
            <p className="titleBlue">CARDIOLOGÍA</p>
            <p className="titleBlue">TRAUMATOLOGÍA</p>
            <p className="titleBlue">NUTRICIÓN</p>
            <p className="titleBlue">GERIATRÍA</p>
          </div>
          <div className="textContainer">
            <p className="titleBlue">ODONTOLOGÍA</p>
            <p className="titleBlue">PSICOLOGÍA</p>
            <p className="titleBlue">ENDOCRINOLOGÍA GENERAL Y PEDIÁTRICA</p>
            <p className="titleBlue">MEDICINA INTERNA</p>
            <p className="titleBlue">MEDICINA FAMILIAR</p>
            <p className="titleBlue">LABORATORIO</p>
            <p className="titleBlue">RED DE FARMACIA</p>
          </div>
          <div className="blueContainer">
            <div className="blueInfo">
              <p>Conmutador: 55 46 31 33 02</p>
              <p className="lighterText">Citas médicas: Ext. 202</p>
              <p className="lighterText">Farmacia: Ext. 205</p>
              <p>Call center: 800 633 42 22</p>
              <p>Call center 24 horas</p>
              <p className="lightText text-xs">
                Dirección: Centro comercial Gran Sur,
              </p>
              <p className="lightText text-xs">
                BUSINESS & THERAPY PLACE (a un lado de Casa de Toño).
              </p>
              <p className="lightText text-xs">
                Av. del Imán No. 151, Col. Pedregal de Carrasco,
              </p>
              <p className="lightTextpad text-xs ">
                Alcaldía Coyoacán, C.P. 04700.
              </p>
            </div>
            <div className="blueBorde">
              <img src={franja} alt="franja" className="franja" />
            </div>
          </div>
        </div>
        <div className="blueContainerMobile">
          <div className="blueInfo">
            <p>Conmutador: 55 46 31 33 02</p>
            <p className="lighterText">Citas médicas: Ext. 202</p>
            <p className="lighterText">Farmacia: Ext. 205</p>
            <p>Call center: 800 633 42 22</p>
            <p>Call center 24 horas</p>
            <p className="lightText text-xs">
              Dirección: Centro comercial Gran Sur,
            </p>
            <p className="lightText text-xs">
              BUSINESS & THERAPY PLACE (a un lado de Casa de Toño).
            </p>
            <p className="lightText text-xs">
              Av. del Imán No. 151, Col. Pedregal de Carrasco,
            </p>
            <p className="lightTextpad text-xs ">
              Alcaldía Coyoacán, C.P. 04700.
            </p>
          </div>
          <div className="blueBorde">
            <img src={franja} alt="franja" className="franja" />
          </div>
        </div>
      </div>
    </section>
  );
}
